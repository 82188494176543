@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville:ital,wght@0,400;0,700;1,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;900&display=swap');

*,
*::after,
*::before {
    border: none;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: 'Lato', serif;
}

/****************/
/** NAV STYING **/
/****************/
.nav {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    background-color: black;
    color: white;
    display: flex;
    justify-content: space-between;
    padding: 1em;
    z-index: 3;
    font-family: 'Lato', serif;
}
.nav a {
    color: white;
    text-decoration: none;
    font-weight: bold;
}
.nav-link-group {
    display: flex;
    align-items: center;
    list-style: none;
}
.nav-link {
    position: relative;
    color: white;
    font-family: 'Lato', serif;
    font-size: 1em;
    margin: 0 2.5em;
    cursor: pointer;
}
.home-link {
    font-size: 1.25em;
}
.nav-link::after,
.footer a::after {
    content: '';
    position: absolute;
    bottom: -2px;
    left: 0;
    height: 1px;
    width: 100%;
    background-color: white;
    transform: scaleX(0);
    opacity: 0;
    transition: 0.2s ease;
}
.nav-link:hover::after,
.footer a:hover::after {
    transform: scaleX(1);
    opacity: 1;
}
.inactive {
    pointer-events: none;
    opacity: 0.3;
}

/*******************/
/** FOOTER STYING **/
/*******************/
.footer {
    padding: 0 3em;
    height: 175px;
    background-color: black;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: solid 1px rgb(20, 20, 20);
}
.socials,
.contact {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
}
.footer a {
    position: relative;
    color: white;
    font-family: 'Lato', serif;
    font-size: 1em;
    text-decoration: none;
}
.footer-img {
    height: 50px;
}

/*****************/
/** HERO STYING **/
/*****************/
.home-hero {
    width: 100vw;
    height: 70vh;
    background: url('./img/bg1.jpg');
    background-position: center;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
}
.hero-header {
    color: white;
    font-family: 'Lato', serif;
    font-size: 7em;
    text-align: center;
}

.event-container {
    height: 70vh;
    width: 100vw;
    position: relative;
    background-color: black;
    overflow: hidden;
}
.inner-event-container {
    height: calc(70vh - 70px);
    width: 200vw;
    display: flex;
    align-items: center;
    transition: 1s ease;
}
.main-event-header {
    display: flex;
    transition: 1s ease;
    border-bottom: solid 1px white;
}
.event-header-background {
    width: 50vw;
    height: 70px;
    background-color: white;
    position: absolute;
    top: 0;
    left: 0;
    transition: 1s ease;
}
.weekly-title,
.main-title {
    width: 100vw;
    text-align: center;
    line-height: 70px;
    cursor: pointer;
    z-index: 1;
}
.weekly-title:hover,
.main-title:hover {
    text-decoration: none;
}
.main-title {
    color: white;
    mix-blend-mode: difference;
}
.weekly-title {
    color: white;
    mix-blend-mode: difference;
}

/*********************/
/** FEATURED STYING **/
/*********************/
.weekly-events {
    width: 100vw;
    height: 60vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: black;
    color: white;
}
.weekly-list {
    position: relative;
    width: 95vw;
    max-width: 1300px;
    height: auto;
    max-height: 80%;
    border: solid 1px rgb(30, 30, 30);
    border-radius: 5px;
    overflow-y: scroll;
    z-index: 2;
    background-color: black;
}
.weekly-header {
    border-bottom: solid 1px rgb(30, 30, 30);
    background-color: rgb(20, 20, 20);
    padding: 7px;
    text-align: center;
}
.weekly-event-list {
    display: flex;
    flex-direction: column;
}
.weekly-event-list .weekly-date h1 {
    padding: 10px;
    font-size: 1.25em;
    border-bottom: solid 1px rgb(30, 30, 30);
}
.weekly-event-list ul {
    list-style: none;
    display: flex;
    align-items: center;
    border-bottom: solid 1px rgb(30, 30, 30);
    /* background-color: rgb(8, 8, 8); */
}
.weekly-date:nth-child(even) {
    background-color: rgb(15, 15, 15);
}
.weekly-name-date {
    display: flex;
    width: 40%;
}
.weekly-event-list ul li {
    padding: 15px 10px;
}
.weekly-name {
    width: 100%;
    border-right: solid 1px rgb(30, 30, 30);
}
.weekly-time {
    width: 25%;
    border-right: solid 1px rgb(30, 30, 30);
    text-align: center;
}
.weekly-location {
    width: 85%;
    text-align: right;
}

/***************************/
/** EVENT CALENDAR STYLES **/
/***************************/
.events-container {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    height: calc(70vh - 70px);
    width: 100vw;
    background-color: white;
}
.event-header {
    font-family: 'Lato', serif;
    font-size: 1.6em;
    text-align: center;
}
.events-list {
    position: relative;
    width: 95vw;
    max-width: 1300px;
    height: auto;
    max-height: 80%;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 5px;
    border: solid 1px rgba(20, 20, 20, 0.2);
    border-top: solid 1px rgba(20, 20, 20, 0.2);
    border-bottom: solid 1px rgba(20, 20, 20, 0.2);
    overflow-y: scroll;
}
.all-events {
    width: 100%;
    overflow-y: scroll;
}
.event {
    width: 100%;
    max-width: 1920px;
    list-style: none;
    display: flex;
    border-bottom: solid 1px rgba(20, 20, 20, 0.2);
    font-family: 'Lato', serif;
    font-weight: 500;
    font-size: 1em;
    color: rgb(20, 20, 20);
}
.event:nth-child(even) {
    background-color: #f5f5f5;
}
.event:nth-last-child(-n + 1) {
    border-bottom: none;
}
.event li {
    padding: 15px 10px;
}
.event-name {
    width: 35%;
}
.event-date {
    width: 20%;
    text-align: left;
    border-right: solid 1px rgba(20, 20, 20, 0.2);
}
.event-location {
    width: 45%;
    text-align: right;
}

/***************************/
/**    LICENSES STYLES    **/
/***************************/

.license {
    background-color: black;
    color: white;
    display: flex;
    gap: 4em;
    flex-direction: column;
    align-items: center;
    padding: 1em 0;
}
.license-sub {
    display: flex;
    gap: 10em;
    align-items: center;
}
.license-sub > div {
    display: flex;
    flex-direction: column;
    gap: 1em;
    font-size: 1.5em;
}
.license h1 {
    text-align: center;
}
.license a {
    color: white;
}
.license h2 {
    font-weight: 100;
    opacity: 0.5;
}



/***************************/
/**    SERVICES STYLES    **/
/***************************/

.services-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-top: solid 1px black;
}
.services-main-title,
.partners-container h1 {
    text-align: center;
    height: 3em;
    font-size: 2em;
    line-height: 3em;
    text-decoration: underline;
}
.home-services {
    background-color: black;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1px;
    border-top: solid 1px black;
    border-bottom: solid 1px black;
}
.service-grid-item {
    height: auto;
    background-color: white;
    font-size: 1.25em;
    padding: 2em;
    color: black;
    text-decoration: none;
}
.service-grid-item:hover {
    text-decoration: underline;
}
.service-grid-item img {
    width: 150px;
}
.services-inner {
    display: flex;
    align-items: center;
}
.services-title {
    width: 300px;
    margin: auto;
}

/***************************/
/**    PARTNERS STYLES    **/
/***************************/

.partners-container {
    background-color: black;
    color: white;
}
.partners-banner {
    padding: 2em 0;
    display: flex;
    justify-content: space-evenly;
}

.partners-grid-item {
    opacity: 0.5;
    height: 200px;
    cursor: pointer;
    transition: opacity 0.2s ease;
}
.partners-grid-item img {
    height: 200px;
}
.partners-grid-item:hover {
    opacity: 1;
}

/***************************/
/**     STAFF STYLES      **/
/***************************/

.staff-container {
    background-color: black;
    width: 100vw;
    height: calc(100vh - 56px);
    margin-top: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.staff-card {
    position: relative;
    overflow: hidden;
    border-radius: 15px;
    width: 400px;
    height: 600px;
    transform: scale(0.8);
}
.staff-image {
    width: 400px;
    height: 650px;
    background-size: contain;
    background-position: center;
    transition: 0.75s ease;
}
.staff-card:hover .staff-image {
    opacity: 0.5;
    transform: translateY(-50px);
}
.staff-card-info {
    position: absolute;
    width: 100%;
    padding: 2em 0;
    text-align: center;
    bottom: 0;
    left: 0;
    color: white;
    opacity: 0;
    transition: 1s ease;
}
.staff-card:hover .staff-card-info {
    bottom: 300px;
    transform: translateY(50%);
    opacity: 1;
}

@media only screen and (max-width: 1200px) {
    .services-main-title,
    .partners-container h1 {
        text-align: center;
        height: 2em;
        font-size: 2em;
        line-height: 2em;
        text-decoration: underline;
    }
    .home-services {
        background-color: black;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 1px;
        border-top: solid 1px black;
        border-bottom: solid 1px black;
    }
    .service-grid-item {
        height: auto;
        background-color: white;
        font-size: 1em;
        padding: 2em;
        color: black;
        text-decoration: none;
    }
    .service-grid-item:hover {
        text-decoration: underline;
    }
    .service-grid-item img {
        width: 100px;
    }
    .services-inner {
        display: flex;
        align-items: center;
    }
    .services-title {
        width: 240px;
        margin: auto;
    }
}

@media only screen and (max-width: 900px) {
    .nav {
        position: absolute;
        justify-content: center;
        padding: 10px;
    }
    .nav-link-group {
        width: 95%;
        justify-content: space-between;
    }
    .home-link {
        display: flex;
    }
    .nav-link {
        font-size: 0.7em;
        margin: 0;
    }
    .nav-link::after,
    .footer a::after {
        display: none;
    }

    .footer {
        padding: 0 1em;
        height: 75px;
    }
    .footer a {
        font-size: 0.7em;
    }
    .footer-img {
        display: none;
    }

    .home-hero {
        height: 70vh;
    }
    .hero-header {
        font-size: 3em;
    }
    .popout-container {
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        padding: 5px;
    }
    .artists-header {
        font-size: 1.5em;
        width: auto;
        margin: 15px 0px;
    }
    .services-modal,
    .artists-modal {
        position: fixed;
        width: 95vw;
        height: 70vh;
        overflow-y: scroll;
        top: 8vh;
        left: 2.5vw;
    }
    .modal-close {
        position: sticky;
        top: 5px;
        left: 5px;
        background-color: rgb(30, 30, 30);
        border: solid 1px white;
        width: 30px;
        height: 30px;
        z-index: 4;
    }
    .modal-close::after {
        font-size: 30px;
        line-height: 30px;
        transform: translate(0.5px, -1px);
    }
    .services-group,
    .artists-group {
        width: 100%;
        display: flex;
        flex-direction: column;
    }
    .artist {
        font-size: 1.2em;
    }

    .event-header-background {
        height: 40px;
    }
    .weekly-title,
    .main-title {
        font-size: 1.2em;
        line-height: 40px;
    }

    .weekly-events {
        height: calc(70vh - 40px);
    }
    .weekly-header {
        font-size: 0.8em;
    }
    .weekly-event {
        display: flex;
        flex-direction: column;
    }
    .weekdate-name {
        font-size: 1em !important;
    }
    .weekly-name-date {
        width: 100%;
        display: flex;
        border-top: solid 1px rgb(30, 30, 30);
        order: 1;
    }
    .weekly-event-list h1 {
        font-size: 0.5em;
    }
    .weekly-event-list ul li {
        font-size: 0.7em;
    }
    .weekly-name {
        width: 75%;
        border-right: solid 1px rgb(30, 30, 30);
    }
    .weekly-time {
        width: 25%;
        border-right: solid 1px rgb(30, 30, 30);
        text-align: center;
    }
    .weekly-location {
        width: 100%;
        text-align: left;
    }

    .inner-event-container {
        height: calc(70vh - 50px);
        margin-top: 5px;
    }

    .events-container {
        height: calc(70vh - 40px);
    }
    .event {
        flex-direction: column;
        border-bottom: solid 4px rgba(20, 20, 20, 0.2);
        font-size: 0.7em;
    }
    .event li {
        border-bottom: solid 1px rgba(20, 20, 20, 0.2);
        padding: 10px;
    }
    .event-name {
        width: 100%;
    }
    .event-date {
        width: 100%;
    }
    .event-location {
        width: 100%;
        text-align: left;
    }

    .partners-container {
        background-color: black;
        color: white;
        padding-top: 2em;
    }
    .partners-banner {
        padding: 1em 0;
        display: grid;
        place-items: center;
        grid-template-columns: repeat(2, 1fr);
    }

    .partners-grid-item {
        opacity: 0.5;
        width: 50vw;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        transition: opacity 0.2s ease;
    }
    .partners-grid-item img {
        width: 35vw;
        height: 35vw;
    }
    .partners-grid-item:hover {
        opacity: 0.5;
    }

    .home-services {
        background-color: black;
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 1px;
        border-top: solid 1px black;
        border-bottom: solid 1px black;
    }
    .service-grid-item {
        height: auto;
        width: 100vw;
        background-color: white;
        font-size: 1em;
        padding: 2em 1em;
        color: black;
        text-decoration: none;
    }
    .service-grid-item:hover {
        text-decoration: underline;
    }
    .service-grid-item img {
        width: 75px;
    }
    .services-inner {
        display: flex;
        align-items: center;
    }
    .services-title {
        width: 217px;
        margin: auto;
    }

    .services-main-title,
    .partners-container h1 {
        text-align: center;
        height: 3em;
        font-size: 1.5em;
        line-height: 3em;
        text-decoration: underline;
    }

    .license {
        background-color: black;
        color: white;
        display: flex;
        gap: 3em;
        flex-direction: column;
        align-items: center;
        padding: 1em 1em;
    }
    .license-sub {
        position: relative;
        display: flex;
        flex-direction: column;
        gap: 3em;
    }
    .license-sub > div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 0.5em;
        font-size: 1.3em;
    }
    .license h1 {
        text-align: center;
        font-size: 1.3em;
    }
    .license a {
        color: white;
    }
    .license h2 {
        font-weight: 100;
        font-size: 1em;
        text-align: center;
        opacity: 0.5;
    }
    .license img {
        width: 70vw;
        max-width: 300px;
    }
}
